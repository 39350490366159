import { Box, Typography } from '@mui/material'
import React from 'react';
import CallIcon from '@mui/icons-material/Call';
import MailIcon from '@mui/icons-material/Mail';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

const ContactUs = () => {
    return (
        <>
            <Box padding={'2rem'} border={'1px dashed'} margin={'1rem'} borderRadius={'10px'}>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Typography color={'cadetblue'} fontSize={'30px'} fontWeight={'700'} fontFamily={'math'} >
                        Contact Us
                    </Typography>
                </Box>
                <Box mt={2}>
                <Typography fontSize={'20px'} fontFamily={'math'} fontWeight={'500'} textAlign={'center'}>
                        Reach us on +91-911-111-2309
                    </Typography>
                </Box>
            </Box>
        </>
    )
}

export default ContactUs