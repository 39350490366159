import axios from "./axios";
import Endpoints from "./Endpoints";

export default class Network {

    static async login(userName, password) {
        var req = { "userName": userName, "password": password };
        let requestOptions = {
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json'
            },
        };
        const response = await axios.post(Endpoints.loginUrl, JSON.stringify(req), requestOptions);
        return response.data;
    }

    static async getMeta() {
        let requestOptions = {
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json'
            },
        };
        const response = await axios.get(Endpoints.metaUrl, requestOptions);
        return response.data;
    }


    static async fetchUser(page, pageSize, userType, parentId, fromDate, toDate, auth) {
        var req = { "page": page, "pageSize": pageSize, "userType": userType, 'parentId': parentId, 'from': fromDate, 'to': toDate };
        let requestOptions = {
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json',
                'X-Auth': auth
            },
        };
        const response = await axios.post(Endpoints.fetchUserUrl, req, requestOptions);
        return response.data;
    }

    static async createUser(body, parentId, auth) {
        var req = body;
        let requestOptions = {
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json',
                'X-Auth': auth
            },
        };
        const response = await axios.post(Endpoints.createUserUrl, req, requestOptions);
        return response.data;

    }

    static async blockUnblockUser(id, auth) {
        let requestOptions = {
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json',
                'X-Auth': auth
            },
        };
        const response = await axios.get(Endpoints.blockUnblockUserUrl + "/" + id, requestOptions);
        return response.data;
    }

    static async editUser(formData, userId, auth) {
        var req = formData;
        let requestOptions = {
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json',
                'X-Auth': auth
            },
        };
        const response = await axios.post(Endpoints.editUserUrl + "/" + userId, req, requestOptions);
        return response.data;
    }

    static async fetchUserDetail(userId, auth) {
        let requestOptions = {
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json',
                'X-Auth': auth
            },
        };
        const response = await axios.get(Endpoints.fetchUserDetailUrl + "/" + userId, requestOptions);
        return response.data;
    }

    static async transferPoints(transferFromId, transferToId, description, points, auth) {
        var req = { "points": points, "transferToId": transferToId, "transferFromId": transferFromId, "description": description }
        let requestOptions = {
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json',
                'X-Auth': auth
            },
        };
        const response = await axios.post(Endpoints.transferPointsUrl, req, requestOptions);
        return response.data;
    }

    static async withdrawPoints(withdrawFromId, withdrawToId, description, points, auth) {
        var req = { "points": points, "withdrawToId": withdrawToId, "withdrawFromId": withdrawFromId, "description": description }
        let requestOptions = {
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json',
                'X-Auth': auth
            },

        };
        const response = await axios.post(Endpoints.withdrawPointsUrl, req, requestOptions);
        return response.data;
    }


    static async fetchTransactions(page, pageSize, userId, auth, fromDate, to) {
        var req = { "page": page, "pageSize": pageSize, 'from': fromDate, 'to': to };
        let requestOptions = {
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json',
                'X-Auth': auth
            },

        };
        const response = await axios.post(Endpoints.fettchTransactionUrl + "/" + userId, req, requestOptions);
        return response.data;
    }

    static async fetchDashBoard(auth) {
        let requestOptions = {
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json',
                'X-Auth': auth
            },

        };
        const response = await axios.get(Endpoints.fettchDashBoardUrl, requestOptions);
        return response.data;
    };

    static async lockDevicesApi(customerId, userId, auth) {
        let requestOptions = {
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json',
                'X-Auth': auth
            },
        };
        const response = await axios.get(Endpoints.lockDevicesUrl + "/" + customerId + "/" + userId, requestOptions);
        return response.data;
    }

    static async unLockDevicesApi(customerId, userId, auth) {
        let requestOptions = {
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json',
                'X-Auth': auth
            },
        };
        const response = await axios.get(Endpoints.unlockDevicesUrl + "/" + customerId + "/" + userId, requestOptions);
        return response.data;
    }

    static async rebootDevicesApi(customerId, auth) {
        let requestOptions = {
            withCredentials: false,
            headers: {
                'Content-Type': 'application/json',
                'X-Auth': auth
            },
        };
        const response = await axios.get(Endpoints.rebootDevicesUrl + "/" + customerId, requestOptions);
        return response.data;
    };

}
