import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography, CardContent, Card, Box, Backdrop } from '@mui/material';
import {
  AppWidgetSummary,
} from '../sections/@dashboard/app/';
import { useContext, useEffect, useState } from 'react';
import Network from '../network/Network';
import AppContext from '../context/appContext';
import { useSnackbar } from "material-ui-snackbar-provider";
import { Circle } from "styled-spinkit";
import { Link } from 'react-router-dom';

// ----------------------------------------------------------------------

// const StyledIcon = styled('div')(({ theme }) => ({
//   margin: 'auto',
//   display: 'flex',
//   borderRadius: '50%',
//   alignItems: 'center',
//   width: theme.spacing(8),
//   height: theme.spacing(8),
//   justifyContent: 'center',
//   marginBottom: theme.spacing(3),
// }));

// ----------------------------------------------------------------------

// AppWidgetSummary.propTypes = {
//   color: PropTypes.string,
//   icon: PropTypes.string,
//   title: PropTypes.string.isRequired,
//   total: PropTypes.number.isRequired,
//   sx: PropTypes.object,
// };

export default function DashboardAppPage() {

  const [dashData, setDashData] = useState([]);
  // const [count, setCount] = useState([]);
  const { auth, type } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const snackbar = useSnackbar();

  async function fetchDashAPI() {
    try {
      setIsLoading(false);
      const resp = await Network.fetchDashBoard(auth);
      if (resp['errorCode'] === 0 && resp['status'] === true) {
        setDashData(resp)
        // setCount(resp['count']);
      }
      else {
        snackbar.showMessage(resp['errorDescription']);
        setIsLoading(true);
      }
    } catch (error) {
      console.log(error);
    };
  };

  useEffect(() => {
    fetchDashAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>
      <Container maxWidth="xl">
        <Typography color={'#061B64'} variant="h4" sx={{ mb: 5 }}>
          Hi, Welcome back
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Wallet Balance" total={dashData?.balance} icon={'solar:wallet-outline'} />
          </Grid>
          {type === 'superAdmin' ?
            <Grid item xs={12} sm={6} md={3} >
              <AppWidgetSummary title="Distributors" total={dashData?.distributors} icon={'mdi:user'} />
            </Grid>
            : ""}
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Retailers" total={dashData?.retailers} icon={'fluent:building-retail-20-filled'} />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Customers" total={dashData?.customers} icon={'ic:sharp-people'} />
          </Grid>

          {/*<Grid item xs={12} md={6} lg={8}>
            <AppWebsiteVisits
              title="Website Visits"
              subheader="(+43%) than last year"
              chartLabels={[
                '01/01/2003',
                '02/01/2003',
                '03/01/2003',
                '04/01/2003',
                '05/01/2003',
                '06/01/2003',
                '07/01/2003',
                '08/01/2003',
                '09/01/2003',
                '10/01/2003',
                '11/01/2003',
              ]}
              chartData={[
                {
                  name: 'Team A',
                  type: 'column',
                  fill: 'solid',
                  data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                },
                {
                  name: 'Team B',
                  type: 'area',
                  fill: 'gradient',
                  data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                },
                {
                  name: 'Team C',
                  type: 'line',
                  fill: 'solid',
                  data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentVisits
              title="Current Visits"
              chartData={[
                { label: 'America', value: 4344 },
                { label: 'Asia', value: 5435 },
                { label: 'Europe', value: 1443 },
                { label: 'Africa', value: 4443 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppConversionRates
              title="Conversion Rates"
              subheader="(+43%) than last year"
              chartData={[
                { label: 'Italy', value: 400 },
                { label: 'Japan', value: 430 },
                { label: 'China', value: 448 },
                { label: 'Canada', value: 470 },
                { label: 'France', value: 540 },
                { label: 'Germany', value: 580 },
                { label: 'South Korea', value: 690 },
                { label: 'Netherlands', value: 1100 },
                { label: 'United States', value: 1200 },
                { label: 'United Kingdom', value: 1380 },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppCurrentSubject
              title="Current Subject"
              chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
              chartData={[
                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
              ]}
              chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppNewsUpdate
              title="News Update"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: faker.name.jobTitle(),
                description: faker.name.jobTitle(),
                image: `/assets/images/covers/cover_${index + 1}.jpg`,
                postedAt: faker.date.recent(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  '1983, orders, $4220',
                  '12 Invoices have been paid',
                  'Order #37745 from September',
                  'New order placed #XF-2356',
                  'New order placed #XF-2346',
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AppTrafficBySite
              title="Traffic by Site"
              list={[
                {
                  name: 'FaceBook',
                  value: 323234,
                  icon: <Iconify icon={'eva:facebook-fill'} color="#1877F2" width={32} />,
                },
                {
                  name: 'Google',
                  value: 341212,
                  icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                },
                {
                  name: 'Linkedin',
                  value: 411213,
                  icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                },
                {
                  name: 'Twitter',
                  value: 443232,
                  icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                },
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <AppTasks
              title="Tasks"
              list={[
                { id: '1', label: 'Create FireStone Logo' },
                { id: '2', label: 'Add SCSS and JS files if required' },
                { id: '3', label: 'Stakeholder Meeting' },
                { id: '4', label: 'Scoping & Estimations' },
                { id: '5', label: 'Sprint Showcase' },
              ]}
            />
          </Grid>
          */}
        </Grid>
      </Container>
      <Container maxWidth="xl" sx={{ marginTop: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6}>
            <Card
              sx={{
                py: 2,
                boxShadow: 0,
                textAlign: 'center',
                background: '#D1E9FC',
              }}
            >
              <CardContent>
                <Typography color={'#061B64'} textAlign={'justify'} fontSize={'20px'} fontWeight={'700'}>
                  Customer Activity
                </Typography>
              </CardContent>
              <Box sx={{ width: '100%' }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
                  <Grid item xs={6} sm={3}>
                    <Typography color={'#061B64'} fontSize={'17px'} fontWeight={'700'}>
                      Inactive
                    </Typography>
                    <Typography color={'#061B64'} fontSize={'21px'} fontWeight={'500'}>{dashData?.activeDevices}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Typography color={'#061B64'} fontSize={'17px'} fontWeight={'700'}>
                      Install
                    </Typography>
                    <Typography color={'#061B64'} fontSize={'21px'} fontWeight={'500'}>{dashData?.installed}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Typography color={'#061B64'} fontSize={'17px'} fontWeight={'700'}>
                      Deactivate
                    </Typography>
                    <Typography color={'#061B64'} fontSize={'21px'} fontWeight={'500'}>{dashData?.inActiveDevices}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Typography color={'#061B64'} fontSize={'17px'} fontWeight={'700'}>
                      Uninstall
                    </Typography>
                    <Typography color={'#061B64'} fontSize={'21px'} fontWeight={'500'}>{dashData?.unInstalled}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Card
              sx={{
                py: 2,
                boxShadow: 0,
                textAlign: 'center',
                background: '#D1E9FC',
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography color={'#061B64'} textAlign={'justify'} fontSize={'20px'} fontWeight={'700'}>
                  Transactions Summary
                </Typography>
                <Link to={'/dashboard/transactions'} style={{ textDecoration: 'none' }}>
                  <Typography fontWeight={'700'} fontSize={'15px'} fontFamily={'math'} color={'#061B64'}>
                    View All
                  </Typography>
                </Link>
              </CardContent>
              <Box sx={{ width: '100%' }}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
                  <Grid item xs={6} sm={3}>
                    <Typography color={'#061B64'} fontSize={'17px'} fontWeight={'700'}>
                      Debit Points
                    </Typography>
                    <Typography color={'#061B64'} fontSize={'21px'} fontWeight={'500'}>{dashData?.debitPoints}</Typography>
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <Typography color={'#061B64'} fontSize={'17px'} fontWeight={'700'}>
                      Credit Points
                    </Typography>
                    <Typography color={'#061B64'} fontSize={'21px'} fontWeight={'500'}>{dashData?.creditPoints}</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
      <Backdrop
        sx={{ color: "aliceblue", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <Circle color={"#fafafa"} size={50} />
      </Backdrop>
    </>
  );
}
