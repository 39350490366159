export default class Endpoints {
    // static  baseUrl="http://110.227.200.246:8080";
    static baseUrl = "https://api.oakmounts.co.in";
    static loginUrl = this.baseUrl + "/user/login";
    static metaUrl = this.baseUrl + "/meta";
    static fetchUserUrl = this.baseUrl + "/user/fetch";
    static createUserUrl = this.baseUrl + "/user/create";
    static editUserUrl = this.baseUrl + "/user/edit";
    static blockUnblockUserUrl = this.baseUrl + "/user/block-unblock";
    static fetchUserDetailUrl = this.baseUrl + "/user/fetch-user-details";
    static transferPointsUrl = this.baseUrl + "/transaction/transfer";
    static withdrawPointsUrl = this.baseUrl + "/transaction/withdraw";
    static fettchTransactionUrl = this.baseUrl + "/transaction/fetch";
    static fettchDashBoardUrl = this.baseUrl + "/meta/dashboard";
    static lockDevicesUrl = this.baseUrl + "/retailer/lock-device";
    static unlockDevicesUrl = this.baseUrl + "/retailer/unlock-device";
    static rebootDevicesUrl = this.baseUrl + "/retailer/reboot-device";
}
