import { Helmet } from "react-helmet-async";
import {
    Card,
    Container,
    Stack,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import Scrollbar from "../components/scrollbar";
import { useContext, useEffect, useState } from "react";
import AppContext from "../context/appContext";
import Network from "../network/Network";
import { useSnackbar } from "material-ui-snackbar-provider";
import Label from "../components/label";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";

export default function Transactions() {
    const TABLE_HEAD = [
        { id: 'sno', label: 'SNo', alignRight: false },
        { id: 'transactionId', label: 'Transaction ID', alignRight: false },
        { id: 'description', label: 'Description', alignRight: false },
        { id: 'amount', label: 'Amount', alignRight: false },
        { id: 'creditDebit', label: 'Credit/Debit To', alignRight: false },
        { id: 'type', label: 'Transaction Type', alignRight: false },
        { id: 'date', label: 'Date', alignRight: false },
        { id: '', label: 'Menu', alignRight: false },
    ];

    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [transactions, setTransactions] = useState([]);
    const [count, setCount] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const { userId, auth } = useContext(AppContext);
    const snackbar = useSnackbar();
    const [fromDate, setFromDate] = useState(null);
    const [to, setToDate] = useState(null);

    const handleFrom = (newValue) => {
        setFromDate(new Date(newValue).getTime());
    };

    const handletoDate = (newValue) => {
        setToDate(new Date(newValue).getTime());
    };

    if (setPageSize) {
        // 
    };

    async function fetchTransactions() {
        const resp = await Network.fetchTransactions(page, pageSize, userId, auth, fromDate, to);
        if (resp['errorCode'] === 0 && resp['status'] === true) {
            setTransactions(resp['transactions'])
            setCount(resp['count']);
        }
        else {
            snackbar.showMessage(resp['errorDescription']);
        };
    };

    useEffect(() => {
        fetchTransactions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fromDate, to]);

    function handleChangePage(event, newPage) {
        setPage(page);
    };

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(event.target.value);
    };

    return (
        <>
            <Helmet>
                <title> Transactions </title>
            </Helmet>

            <Container maxWidth="xl">
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Transactions
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            label="From Date"
                            inputFormat="YYYY/MM/DD"
                            value={fromDate}
                            onChange={(newValue) => handleFrom(newValue)}
                            renderInput={(params) => <TextField sx={{ minWidth: 180 }} {...params} />}

                        />
                    </LocalizationProvider>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            label="To Date"
                            inputFormat="YYYY/MM/DD"
                            value={to}
                            onChange={(newValue) => handletoDate(newValue)}
                            renderInput={(params) => <TextField sx={{ minWidth: 180 }} {...params} />}

                        />
                    </LocalizationProvider>
                </Stack>
                <Card>
                    <Scrollbar>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {TABLE_HEAD.map((headCell) => (
                                            <TableCell
                                                key={headCell.id}
                                                align={headCell.alignRight ? 'right' : 'left'}
                                            // sortDirection={orderBy === headCell.id ? order : false}
                                            >
                                                {headCell.label}
                                            </TableCell>
                                        ))}

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        transactions.map((transaction, index) => {
                                            return <TableRow hover key={index}>
                                                <TableCell align="left">{index + (page * pageSize) + 1}</TableCell>
                                                <TableCell align="left">{transaction.transactionId}</TableCell>

                                                <TableCell align="left">{transaction.description}</TableCell>

                                                <TableCell align="left">{transaction.points}</TableCell>
                                                <TableCell align="left">{transaction.debitCreditUserName}</TableCell>
                                                <TableCell align="left">
                                                    {
                                                        transaction.type === 'credit' ? <Label color={'success'}>credit</Label> : <Label color={'error'}>debit</Label>
                                                    }
                                                </TableCell>
                                                <TableCell align="left">{moment(transaction.timestamp).format('do MMMM YYYY, h:mm:ss a')}</TableCell>
                                            </TableRow>
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
        </>
    );
}
