import { useContext, useEffect, useState } from "react";
import AppContext from "../context/appContext";
import { useSnackbar } from "material-ui-snackbar-provider";
import { useNavigate, useSearchParams } from "react-router-dom";
import Network from "../network/Network";
import { Helmet } from "react-helmet-async";
import {
    Avatar,
    Button,
    Card,
    Container, Dialog, DialogTitle, IconButton, MenuItem, Popover,
    Stack,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow,
    TextField,
    Typography,
    useMediaQuery
} from "@mui/material";
import Iconify from "../components/iconify";
import Scrollbar from "../components/scrollbar/Scrollbar";
import Label from "../components/label";
import CreateUserForm from "../components/create_user_form";
import EditUserForm from "../components/edit_user_form";
import TransferPointsForm from "../components/transfer_points_form";
import WithdrawPointsForm from "../components/withdrawal_points_form";
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha, useTheme } from '@mui/material/styles';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import '../App.css'

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));


export default function Customers() {
    const TABLE_HEAD = [
        { id: 'name', label: 'Name', alignRight: false },
        { id: 'admin', label: 'Admin', alignRight: false },
        { id: 'company', label: 'Company', alignRight: false },
        { id: 'imei', label: 'IMEI', alignRight: false },
        { id: 'createdAt', label: 'CreatedAt', alignRight: false },
        { id: 'status', label: 'Status', alignRight: false },
        { id: 'action', label: 'Action', alignRight: false },
    ];

    const [open, setOpen] = useState(null);
    const theme = useTheme();
    const isOnMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [page, setPage] = useState(0);
    const [users, setUsers] = useState([]);
    const [pageSize, setPageSize] = useState(25);
    const [menuUser, setMenuUser] = useState(null);
    const [newUserDialogOpen, setNewUserdDialogOpen] = useState(false);
    const [editUserDialogOpen, setEditUserdDialogOpen] = useState(false);
    const [transferFormOpen, setTransferFormOpen] = useState(false);
    const [withdrawalFormOpen, setWithdrawalFormOpen] = useState(false);
    const [count, setCount] = useState(0);
    const { userId, auth, userType } = useContext(AppContext);
    const snackbar = useSnackbar();
    const [params, setParams] = useSearchParams();
    let parentId = params.get("parentId");
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [searchQuery, setSearchQuery] = useState('');
    const [fromDate, setFromDate] = useState(null);
    const [to, setToDate] = useState(null);

    // if (setPageSize, setParams) {
    //     // 
    // };

    const handleOpenMenu = (event, user) => {
        setMenuUser(user);
        setOpen(event.currentTarget);
    };

    const handleFrom = (newValue) => {
        setFromDate(new Date(newValue).getTime());
    };

    const handletoDate = (newValue) => {
        setToDate(new Date(newValue).getTime());
    };

    // const handleOpenMenu = (event, user) => {
    //     setMenuUser(user);
    //     setOpen(event.currentTarget);
    // };

    const handleCloseMenu = () => {
        setOpen(null);
        setMenuUser(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(event.target.value);
    };

    const navigate = useNavigate();
    useEffect(() => {
        if (!(userType !== 'superDistributor' || userType !== 'distributor')) {
            navigate('/404');
        };
        if (userType === 'distributor') {
            parentId = userId;
        };
        if (userType === 'retailer') {
            parentId = null;
        };
        fetchCustomers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, fromDate, to]);

    async function fetchCustomers() {
        const resp = await Network.fetchUser(page, pageSize, 'customer', parentId, fromDate, to, auth);
        if (resp['errorCode'] === 0 && resp['status'] === true) {
            setUsers(resp['users']);
            setCount(resp['count']);
        }
        else {
            snackbar.showMessage(resp['errorDescription']);
        };
    };

    const handleSearchByIMEIChange = async (event) => {
        const imeiQuery = event.target.value;
        setSearchQuery(imeiQuery);
        try {
            const response = await Network.fetchUser(
                userId,
                'customer',
                page,
                pageSize,
                auth,
                null, // Set parentId to null if not needed
                imeiQuery  // Pass the imeiQuery as an additional parameter
            );

            if (response['errorCode'] === 0 && response['status'] === true) {
                const filteredCustomers = response['users'].filter((customer) => {
                    const customerIMEI = customer?.customerDevice.imei ? customer?.customerDevice.imei.toLowerCase() : "";
                    const imeiQueryLower = imeiQuery.toLowerCase();
                    return customerIMEI.includes(imeiQueryLower);
                });

                setUsers(filteredCustomers);
                setCount(filteredCustomers.length);
            } else {
                snackbar.showMessage(response['errorDescription']);
            }
        } catch (error) {
            console.error(error);
        }
    };

    function handleNewClick() {
        setNewUserdDialogOpen(true);
    };

    function handleNewUserDialogClose() {
        setNewUserdDialogOpen(false);
    };

    function onUserSuccess() {
        handleNewUserDialogClose();
        handleEditUserDialogClose();
        setOpen(null);
        setTransferFormOpen(false);
        setWithdrawalFormOpen(false);
        fetchCustomers();
    };

    async function handleBlockClickEdit() {
        if (menuUser === null || menuUser === undefined) {
            return;
        }
        const resp = await Network.blockUnblockUser(menuUser.id, auth);
        if (resp) {
            // 
        };
        setOpen(null);
        fetchCustomers();
    };

    async function handleEditUserClick() {
        setEditUserdDialogOpen(open);
    };

    function handleEditUserDialogClose() {
        setEditUserdDialogOpen(false);
    };

    function handleTransferUserClick() {
        setTransferFormOpen(true);
    };

    function handleWithdrawalUserClick() {
        setWithdrawalFormOpen(true);
    };

    function handleTransferUserDialogClose() {
        setTransferFormOpen(false);
    };

    function handleWithdrawFormClose() {
        setWithdrawalFormOpen(false);
    };

    async function handleChangeLockUnclockDevice() {
        if (menuUser?.customerDevice?.locked === true) {
            const resp = await Network.unLockDevicesApi(menuUser.id, 0, auth);
            if (resp.status === true) {
                setOpen(null);
                fetchCustomers();
            };
        } else {
            const resp = await Network.lockDevicesApi(menuUser.id, 0, auth);
            if (resp.status === true) {
                setOpen(null);
                fetchCustomers();
            };
        }
    };

    async function handleChangeRebootDevice() {
        const resp = await Network.rebootDevicesApi(menuUser.id, auth);
        if (resp.status === true) {
            setOpen(null);
            fetchCustomers();
        };
    };


    return (
        <>
            <Helmet>
                <title> Customers </title>
            </Helmet>

            <Container maxWidth="xl">
                {isOnMobile ?
                    <Stack direction="column" alignItems="center" justifyContent="space-between" mb={5} gap={'15px'}>
                        <Typography variant="h4" gutterBottom>
                            Customers
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="From Date"
                                inputFormat="YYYY/MM/DD"
                                value={fromDate}
                                onChange={(newValue) => handleFrom(newValue)}
                                renderInput={(params) => <TextField sx={{ minWidth: 180 }} {...params} />}

                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="To Date"
                                inputFormat="YYYY/MM/DD"
                                value={to}
                                onChange={(newValue) => handletoDate(newValue)}
                                renderInput={(params) => <TextField sx={{ minWidth: 180 }} {...params} />}

                            />
                        </LocalizationProvider>
                        {userType === "distributor" &&
                            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}
                                onClick={handleNewClick}>
                                New User
                            </Button>}
                        {/* <Search>
                <SearchIconWrapper>
                    <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                    placeholder="Search IMEI…"
                    inputProps={{ 'aria-label': 'search' }}
                    value={searchQuery}
                    onChange={handleSearchByIMEIChange}
                />
            </Search> */}
                    </Stack>
                    :
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Customers
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="From Date"
                                inputFormat="YYYY/MM/DD"
                                value={fromDate}
                                onChange={(newValue) => handleFrom(newValue)}
                                renderInput={(params) => <TextField sx={{ minWidth: 180 }} {...params} />}

                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="To Date"
                                inputFormat="YYYY/MM/DD"
                                value={to}
                                onChange={(newValue) => handletoDate(newValue)}
                                renderInput={(params) => <TextField sx={{ minWidth: 180 }} {...params} />}

                            />
                        </LocalizationProvider>
                        {userType === "distributor" &&
                            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}
                                onClick={handleNewClick}>
                                New User
                            </Button>}
                        {/* <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search IMEI…"
                            inputProps={{ 'aria-label': 'search' }}
                            value={searchQuery}
                            onChange={handleSearchByIMEIChange}
                        />
                    </Search> */}
                    </Stack>
                }

                <Card>
                    <Scrollbar>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {TABLE_HEAD.map((headCell) => (
                                            <TableCell
                                                key={headCell.id}
                                                align={headCell.alignRight ? 'right' : 'left'}
                                            // sortDirection={orderBy === headCell.id ? order : false}
                                            >
                                                {headCell.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map((user, index) => {
                                        return <TableRow hover key={index}>
                                            <TableCell component="th" scope="row" padding="none">
                                                <Stack direction="row" alignItems="center" spacing={2} sx={{ margin: 2 }}>
                                                    <Avatar src={"/assets/images/avatars/avatar_" + (index + 1).toString() + ".jpg"} />

                                                    <Typography variant="subtitle2" noWrap>
                                                        {user.firstName} {user.lastName}
                                                    </Typography>
                                                </Stack>
                                            </TableCell>
                                            <TableCell align="left">{user.admin}</TableCell>
                                            <TableCell align="left">{user.company}</TableCell>
                                            <TableCell align="left">{user?.customerDevice?.imei}</TableCell>
                                            <TableCell align="left">
                                                {moment(user.createdAt).isValid() ? moment(user.createdAt).format('Do MMMM YYYY, h:mm a') : "N/A"}
                                            </TableCell>
                                            <TableCell align="left">
                                                <Label
                                                    color={user.customerDevice.active === true ? 'success' : 'error'}>Active</Label>
                                            </TableCell>
                                            <TableCell align="center">
                                                <IconButton size="large" color="inherit"
                                                    onClick={(event) => { handleOpenMenu(event, user) }}>
                                                    <Iconify icon={'eva:more-vertical-fill'} />
                                                </IconButton>
                                            </TableCell>
                                            {/* <TableCell sx={{ cursor: 'pointer' }} align="left">
                                                <Label
                                                    onClick={() => handleChangeLockUnclockDevice(user)}
                                                    color={user.customerDevice.locked !== true ? 'success' : 'error'}>{user.customerDevice.locked === true ? "Locked" : "UnLocked"}</Label>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Label
                                                    onClick={() => handleChangeRebootDevice(user)}
                                                >
                                                    Reboot Device
                                                </Label>
                                            </TableCell> */}
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>

            <Dialog open={newUserDialogOpen} onClose={handleNewUserDialogClose}>
                <DialogTitle>Create</DialogTitle>
                <CreateUserForm userType={'retailer'} onClose={handleNewUserDialogClose} onSuccess={onUserSuccess}
                    parentId={userId} />
            </Dialog>
            <Dialog open={editUserDialogOpen} onClose={handleEditUserDialogClose}>
                <DialogTitle>Edit</DialogTitle>
                <EditUserForm userType={'retailer'} onClose={handleEditUserDialogClose} onSuccess={onUserSuccess}
                    parentId={userId} user={menuUser} />
            </Dialog>
            <Dialog open={transferFormOpen} onClose={handleTransferUserDialogClose}>
                <DialogTitle>Transfer</DialogTitle>
                <TransferPointsForm parentId={userId} childUser={menuUser} onClose={handleTransferUserDialogClose}
                    onSuccess={onUserSuccess} />
            </Dialog>

            <Dialog open={withdrawalFormOpen} onClose={handleWithdrawFormClose}>
                <DialogTitle>Withdraw</DialogTitle>
                <WithdrawPointsForm parentId={userId} childUser={menuUser} onClose={handleWithdrawFormClose}
                    onSuccess={onUserSuccess} />
            </Dialog>
            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={handleChangeLockUnclockDevice}>
                    {
                        menuUser?.customerDevice?.locked === true ?
                            <>
                                <Iconify icon={'material-symbols:lock-outline'} sx={{ mr: 2 }} />
                                Lock
                            </> : <>
                                <Iconify icon={'fluent-emoji-high-contrast:unlocked'} sx={{ mr: 2 }} />
                                UnLocked
                            </>
                    }
                </MenuItem>
                <MenuItem onClick={handleChangeRebootDevice}>
                    <Iconify icon={'arcticons:simplereboot'} sx={{ mr: 2 }} />
                    Reboot
                </MenuItem>
            </Popover>
        </>
    );
}
