import { Helmet } from 'react-helmet-async';
import { useContext, useEffect, useState } from 'react';
import {
    Avatar,
    Button,
    Card,
    Container,
    Dialog,
    DialogTitle,
    IconButton,
    MenuItem,
    Popover,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Network from "../network/Network";
import AppContext from "../context/appContext";
import { useSnackbar } from "material-ui-snackbar-provider";
import CreateUserForm from "../components/create_user_form";
import EditUserForm from "../components/edit_user_form";
import TransferPointsForm from "../components/transfer_points_form";
import WithdrawPointsForm from "../components/withdrawal_points_form";
import { useNavigate } from "react-router-dom";
import moment from 'moment';

const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'userName', label: 'UserName', alignRight: false },
    { id: 'admin', label: 'Admin', alignRight: false },
    { id: 'company', label: 'Company', alignRight: false },
    { id: 'balance', label: 'Balance', alignRight: false },
    { id: 'createdAt', label: 'CreatedAt', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: '', label: 'Menu', alignRight: false },
];

export default function DistributorPage() {

    const [open, setOpen] = useState(null);
    const theme = useTheme();
    const navigate = useNavigate();
    const isOnMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [page, setPage] = useState(0);
    const [users, setUsers] = useState([]);
    const [pageSize, setPageSize] = useState(25);
    const [menuUser, setMenuUser] = useState(null);
    const [newUserDialogOpen, setNewUserdDialogOpen] = useState(false);
    const [editUserDialogOpen, setEditUserdDialogOpen] = useState(false);
    const [transferFormOpen, setTransferFormOpen] = useState(false);
    const [withdrawalFormOpen, setWithdrawalFormOpen] = useState(false);
    const [count, setCount] = useState(0);
    const { userId, auth, type } = useContext(AppContext);
    const snackbar = useSnackbar();
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [fromDate, setFromDate] = useState(null);
    const [to, setToDate] = useState(null);

    // if (setPageSize, setParams) {
    //     // 
    // };

    const handleFrom = (newValue) => {
        setFromDate(new Date(newValue).getTime());
    };

    const handletoDate = (newValue) => {
        setToDate(new Date(newValue).getTime());
    };

    if (setPageSize) {
        // 
    };

    const handleOpenMenu = (event, user) => {
        setMenuUser(user);
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
        setMenuUser(null);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(event.target.value);
    };

    useEffect(() => {
        // console.log(user, type);
        // if (localStorage.getItem('userType') !== 'superDistributor') {
        //     navigate('/404');
        // }
        fetchDistributors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, fromDate, to]);

    console.log('from', fromDate);
    console.log('fromto', to);

    async function fetchDistributors() {
        const resp = await Network.fetchUser(page, pageSize, 'distributor', userId, fromDate, to, auth);
        if (resp['errorCode'] === 0 && resp['status'] === true) {
            setUsers(resp['users']);
            setCount(resp['count']);
        }
        else {
            snackbar.showMessage(resp['errorDescription']);
        };
    };

    function handleNewClick() {
        setNewUserdDialogOpen(true);
    };

    function handleNewUserDialogClose() {
        setNewUserdDialogOpen(false);
    };

    function onUserSuccess() {
        handleNewUserDialogClose();
        handleEditUserDialogClose();
        setOpen(null);
        setTransferFormOpen(false);
        setWithdrawalFormOpen(false);

        fetchDistributors();
    };

    async function handleBlockClickEdit() {
        if (menuUser === null || menuUser === undefined) {
            return;
        };
        const resp = await Network.blockUnblockUser(menuUser.id, auth);
        if (resp) {
            // 
        };
        setOpen(null);
        fetchDistributors();
    };

    async function handleEditUserClick() {
        setEditUserdDialogOpen(open);
    };

    function handleEditUserDialogClose() {
        setEditUserdDialogOpen(false);
    };

    function handleTransferUserClick() {
        setTransferFormOpen(true);
    };

    function handleWithdrawalUserClick() {
        setWithdrawalFormOpen(true);
    };

    function handleTransferUserDialogClose() {
        setTransferFormOpen(false);
    };

    function handleWithdrawFormClose() {
        setWithdrawalFormOpen(false);
    };

    return (
        <>
            <Helmet>
                <title> Distributors </title>
            </Helmet>
            <Container maxWidth="xl">
                {isOnMobile ?
                    <Stack direction="column" alignItems="center" justifyContent="space-between" mb={5} gap={'15px'}>
                        <Typography variant="h4" gutterBottom>
                            Distributors
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="From Date"
                                inputFormat="YYYY/MM/DD"
                                value={fromDate}
                                onChange={(newValue) => handleFrom(newValue)}
                                renderInput={(params) => <TextField sx={{ minWidth: 180 }} {...params} />}
                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="To Date"
                                inputFormat="YYYY/MM/DD"
                                value={to}
                                onChange={(newValue) => handletoDate(newValue)}
                                renderInput={(params) => <TextField sx={{ minWidth: 180 }} {...params} />}

                            />
                        </LocalizationProvider>
                        {type === 'superAdmin' &&
                            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}
                                onClick={handleNewClick}>
                                New Distributor
                            </Button>}
                    </Stack>
                    :
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <Typography variant="h4" gutterBottom>
                            Distributors
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="From Date"
                                inputFormat="YYYY/MM/DD"
                                value={fromDate}
                                onChange={(newValue) => handleFrom(newValue)}
                                renderInput={(params) => <TextField sx={{ minWidth: 180 }} {...params} />}

                            />
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                                label="To Date"
                                inputFormat="YYYY/MM/DD"
                                value={to}
                                onChange={(newValue) => handletoDate(newValue)}
                                renderInput={(params) => <TextField sx={{ minWidth: 180 }} {...params} />}

                            />
                        </LocalizationProvider>
                        {type === 'superAdmin' &&
                            <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}
                                onClick={handleNewClick}>
                                New Distributor
                            </Button>}
                    </Stack>
                }
                <Card>
                    {/*<UserListToolbar numSelected={selected.length} filterName={filterName}*/}
                    {/*                 onFilterName={handleFilterByName}/>*/}
                    <Scrollbar>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {/*<TableCell padding="checkbox">*/}
                                        {/*<Checkbox*/}
                                        {/*    indeterminate={numSelected > 0 && numSelected < rowCount}*/}
                                        {/*    checked={rowCount > 0 && numSelected === rowCount}*/}
                                        {/*    onChange={onSelectAllClick}*/}
                                        {/*/>*/}
                                        {/*</TableCell>*/}
                                        {TABLE_HEAD.map((headCell) => (
                                            <TableCell
                                                key={headCell.id}
                                                align={headCell.alignRight ? 'right' : 'left'}
                                            // sortDirection={orderBy === headCell.id ? order : false}
                                            >
                                                {/*<TableSortLabel*/}
                                                {/*    hideSortIcon*/}
                                                {/*    // active={orderBy === headCell.id}*/}
                                                {/*    // direction={orderBy === headCell.id ? order : 'asc'}*/}
                                                {/*    // onClick={handleRequestSort(headCell.id)}*/}
                                                {/*>*/}
                                                {headCell.label}
                                                {/*    {orderBy === headCell.id ? (*/}
                                                {/*        <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>*/}
                                                {/*    ) : null}*/}
                                                {/*</TableSortLabel>*/}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users.map((user, index) => {
                                        return <TableRow hover key={index}>
                                            <TableCell component="th" scope="row" padding="none">
                                                <Stack direction="row" alignItems="center" spacing={2} sx={{ margin: 2 }}>
                                                    <Avatar
                                                        src={"/assets/images/avatars/avatar_" + (index - 1).toString() + ".jpg"} />
                                                    <Button
                                                        onClick={() => { navigate("/dashboard/retailers?parentId=" + user.id) }}
                                                        variant="text"> <Typography variant="subtitle2" noWrap
                                                            color={"black"}>{user.firstName} {user.lastName}</Typography>
                                                    </Button>
                                                </Stack>
                                            </TableCell>
                                            <TableCell align="left">{user.userName}</TableCell>
                                            <TableCell align="left">{user.admin}</TableCell>
                                            <TableCell align="left">{user.company}</TableCell>
                                            <TableCell align="left">{user.balance}</TableCell>
                                            <TableCell align="left">{moment(user.createdAt).format('do MMMM YYYY, h:mm a')}</TableCell>
                                            <TableCell align="left">
                                                <Label
                                                    color={user.status === true ? 'success' : 'error'}>Active</Label>
                                            </TableCell>
                                            <TableCell align="right">
                                                <IconButton size="large" color="inherit"
                                                    onClick={(event) => { handleOpenMenu(event, user) }}>
                                                    <Iconify icon={'eva:more-vertical-fill'} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Scrollbar>
                    <TablePagination
                        rowsPerPageOptions={[25, 50, 100]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
            </Container>
            <Dialog open={newUserDialogOpen} onClose={handleNewUserDialogClose}>
                <DialogTitle>Create</DialogTitle>
                <CreateUserForm userType={'distributor'} onClose={handleNewUserDialogClose} onSuccess={onUserSuccess}
                    parentId={userId} />
            </Dialog>
            <Dialog open={editUserDialogOpen} onClose={handleEditUserDialogClose}>
                <DialogTitle>Edit</DialogTitle>
                <EditUserForm userType={'distributor'} onClose={handleEditUserDialogClose} onSuccess={onUserSuccess}
                    parentId={userId} user={menuUser} />
            </Dialog>
            <Dialog open={transferFormOpen} onClose={handleTransferUserDialogClose}>
                <DialogTitle>Transfer</DialogTitle>
                <TransferPointsForm parentId={userId} childUser={menuUser} onClose={handleTransferUserDialogClose}
                    onSuccess={onUserSuccess} />
            </Dialog>
            <Dialog open={withdrawalFormOpen} onClose={handleWithdrawFormClose}>
                <DialogTitle>Withdraw</DialogTitle>
                <WithdrawPointsForm parentId={userId} childUser={menuUser} onClose={handleWithdrawFormClose}
                    onSuccess={onUserSuccess} />
            </Dialog>
            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={handleEditUserClick}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem>
                {
                    menuUser !== null ? menuUser.parentId === parseInt(userId) ?
                        <><MenuItem onClick={handleTransferUserClick}>
                            <Iconify icon={'eva:corner-down-right-outline'} sx={{ mr: 2 }} />
                            Transfer
                        </MenuItem>
                            <MenuItem onClick={handleWithdrawalUserClick}>
                                <Iconify icon={'eva:corner-down-left-outline'} sx={{ mr: 2 }} />
                                Withdrawal
                            </MenuItem>
                        </> : <></> : <></>
                }
                {
                    menuUser !== null ? menuUser.status === true ?
                        <MenuItem sx={{ color: 'error.main' }} onClick={handleBlockClickEdit}>
                            <Iconify icon={'eva:slash-outline'} sx={{ mr: 2 }} />
                            Block
                        </MenuItem> : <MenuItem sx={{ color: 'success.main' }} onClick={handleBlockClickEdit}>
                            <Iconify icon={'eva:radio-button-on-outline'} sx={{ mr: 2 }} />
                            Unblock
                        </MenuItem> : <></>
                }
            </Popover>
        </>
    );
}


