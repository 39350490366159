import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Box,  Dialog } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import logo from './Sarthiemilocker_20231012_144542_0000.png';
// sections
import { LoginForm } from '../sections/auth/login';
import ContactUs from './ContactUs/ContactUs';
import { useState } from 'react';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 500,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const mdUp = useResponsive('up', 'md');

  const [openContactForm, setOpenContactForm] = useState(false);

  const handleOpenContactForm = () => {
    setOpenContactForm(true);
  };

  const handleCloseContactForm = () => {
    setOpenContactForm(false);
  };

  return (
    <>
      <Helmet>
        <title> Login  </title>
      </Helmet>

      <StyledRoot>
        {/* <Logo
          sx={{
            position: 'fixed',
            top: { xs: 16, sm: 24, md: 40 },
            left: { xs: 16, sm: 24, md: 40 },
          }}
        /> */}
        {mdUp && (
          <StyledSection>

            <Typography variant="h3" sx={{ px: 5, mt: -8, mb: 16 }}>
              Hi, Welcome Back
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: -8 }}>
              {/* <Logo /> */}
              <img alt='Logo' width={'50%'} src={logo} />
            </Box>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}
        <Container maxWidth="sm">
          <Box sx={{ display: ['flex', 'none'], justifyContent: 'center', alignItems: 'center' }}>
            {/* <Logo /> */}
            <img alt='Logo' width={['50%', '50%']} style={{ marginBottom: '-7rem', marginTop: '2rem' }} src={logo} />
          </Box>
          <StyledContent>
            <Typography variant="h4" gutterBottom >
              Sign in to Sarthi Global
            </Typography>

            <Typography variant="body2" sx={{ mb: 5 }}>
              Don’t have an account? {''}
              <Link onClick={handleOpenContactForm} variant="subtitle2">Get started</Link>
            </Typography>

            {/*<Stack direction="row" spacing={2}>*/}
            {/*  <Button fullWidth size="large" color="inherit" variant="outlined">*/}
            {/*    <Iconify icon="eva:google-fill" color="#DF3E30" width={22} height={22} />*/}
            {/*  </Button>*/}

            {/*  <Button fullWidth size="large" color="inherit" variant="outlined">*/}
            {/*    <Iconify icon="eva:facebook-fill" color="#1877F2" width={22} height={22} />*/}
            {/*  </Button>*/}

            {/*  <Button fullWidth size="large" color="inherit" variant="outlined">*/}
            {/*    <Iconify icon="eva:twitter-fill" color="#1C9CEA" width={22} height={22} />*/}
            {/*  </Button>*/}
            {/*</Stack>*/}

            {/*<Divider sx={{ my: 3 }}>*/}
            {/*  <Typography variant="body2" sx={{ color: 'text.secondary' }}>*/}
            {/*    OR*/}
            {/*  </Typography>*/}
            {/*</Divider>*/}

            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
      <Dialog
        open={openContactForm}
        onClose={handleCloseContactForm}
        PaperProps={{
          style: {
            width: '4000px', // Set your desired width here
            // You can also add other styles if needed
          },
        }}
      >
        <ContactUs />
      </Dialog>
    </>
  );
}
